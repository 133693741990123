<template>
  <div id="tutorials">
    <v-container class="py-0">
      <v-card>
        <v-card-title class="text-h5">
          <h2>Tutorials</h2>
        </v-card-title>

        <v-card-subtitle class="text-h5">
          <h4>Frequently asked questions</h4>
        </v-card-subtitle>

        <v-card-text>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-expansion-panels focusable popout>
            <v-expansion-panel v-for="(item, i) in rows" :key="i">
              <v-expansion-panel-header>
                <h3> {{ item.Title }} </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p v-html="item.Content"></p>
                <vue-plyr>
                  <div
                    data-plyr-provider="youtube"
                    :data-plyr-embed-id="item.YoutubeVideoId"
                  ></div>
                </vue-plyr>
                <p></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  name: "Error-6",
  mixins: [common],
  data() {
    return {
      LoadingFlag: false,
      ResultFlag: false,

      rows: [],
    };
  },
  mounted() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg6.jpg";
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var LomCode = 1;
      console.log("LomCode=" + LomCode);

      if (LomCode != null) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/pages/tutorials";
        var upload = {
          UserInterface: 1,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.rows = [];
        this.ResultFlag = false;
        this.LoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.rows = records.TableData;

            if (flag == 1) {
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = true;
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "LOM should not be empty.";
        console.log("error=" + message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/error/error-6.scss";
#tutorials {
  .animate-button1 {
    display: inline-block;
    margin: 0 0.5rem;
    color: white;

    animation: bounce; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 2s; /* don't forget to set a duration! */
  }
}
</style>